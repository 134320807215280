<template>
  <div>
    <v-app class="mx-auto">
      <v-app-bar app dense color="#fff" height="70px" style="z-index: 9">
        <v-app-bar-nav-icon
          large
          @click="drawer = true"
          color="blue"
        ></v-app-bar-nav-icon>

        <v-img
          max-width="150px"
          class="ml-5 mt-2"
          src="@/assets/logo/BKLTH.png"
          @click="gotoDotNet()"
        ></v-img>
        <v-spacer></v-spacer>
        <v-btn icon to="/cart" v-if="$store.state.Carts.length === 0">
          <v-icon class="text-h5 mr-2" color="blue">mdi-cart-outline</v-icon>
        </v-btn>
        <v-btn icon to="/cart" v-if="$store.state.Carts.length">
          <v-badge
            color="green"
            overlap
            :content="$store.state.Carts.length"
            v-if="$store.state.Carts.length"
          >
            <v-icon class="text-h5 mr-2" color="blue">mdi-cart-outline</v-icon>
          </v-badge>
        </v-btn>
        <v-btn icon to="/member" v-if="!isLogedin">
          <v-icon class="text-h5 mr-2" color="blue">mdi-lock-outline</v-icon>
        </v-btn>
        <v-btn icon v-if="isLogedin" @click="logout()">
          <v-icon class="text-h5 mr-2" color="blue">mdi-logout-variant</v-icon>
        </v-btn>
      </v-app-bar>

      <!-- <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        color="#E2C878"
        style="
          position: fixed;
          top: 0;
          left: 0;
          overflow-y: scroll;
          z-index: 10;
        "
      >
        <v-img
          max-width="300px"
          max-height="300px"
          class="mx-auto my-5"
          src="@/assets/logo/BKLTH.png"
        ></v-img>
        <v-list nav dense>
          <v-list-item-group v-model="group" active-class="text--accent-5">
            <v-list-item
              v-for="item in Navigator"
              :key="item.key"
              @click="ChangePage(item)"
            >
              <v-icon class="text-h5 mr-2" color="#000">{{ item.icon }}</v-icon>
              <v-badge
                color="green"
                :content="$store.state.Carts.length"
                inline
                v-if="$store.state.Carts.length && item.name == 'cart'"
              >
              </v-badge>
              <span style="font-size: 20px">{{ item.text }}</span>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer> -->
      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        style="
          position: fixed;
          top: 0;
          left: 0;
          overflow-y: scroll;
          z-index: 10;
        "
      >
        <v-img
          max-width="200px"
          class="mx-auto my-5"
          src="@/assets/logo/BKLTH.png"
          @click="gotoDotNet()"
        ></v-img>
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="text--accent-5"
            class="text-center"
          >
            <v-divider></v-divider>
            <v-list-item
              class="text-center"
              @click="
                ChangePage({
                  key: 'menuIndex',
                  text: 'index',
                  path: '/',
                  name: 'index',
                  icon: 'mdi-home',
                })
              "
            >
              <p style="margin: 0px">
                <b
                  style="font-size: 0.8rem"
                  @click="
                    ChangePage({
                      key: 'menuIndex',
                      text: 'index',
                      path: '/',
                      name: 'index',
                      icon: 'mdi-home',
                    })
                  "
                  >หน้าหลัก</b
                >
              </p>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="text-center">
              <p style="margin: 0px">
                <b style="font-size: 0.8rem">เลือกซื้อลอตเตอรี่</b>
              </p>
            </v-list-item>
            <v-list-item
              style="margin-top: 0px"
              @click="
                ChangePage({
                  key: 'menulotterythai',
                  text: 'lotterythai',
                  path: '/lotterythai',
                  name: 'lotterythai',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="150px"
                class="mx-auto my-0"
                src="@/assets/logo/THLLogo.png"
                @click="
                  ChangePage({
                    key: 'menulotterythai',
                    text: 'lotterythai',
                    path: '/lotterythai',
                    name: 'lotterythai',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="
                ChangePage({
                  key: 'menuPowerBall',
                  text: 'Power Ball',
                  path: '/powerBall',
                  name: 'powerBall',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="150px"
                class="mx-auto my-5"
                src="@/assets/logo/Logo_USA.png"
                @click="
                  ChangePage({
                    key: 'menuPowerBall',
                    text: 'Power Ball',
                    path: '/powerBall',
                    name: 'powerBall',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="
                ChangePage({
                  key: 'megamillions',
                  text: 'Mega Millions',
                  path: '/megamillions',
                  name: 'megamillions',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="150px"
                class="mx-auto my-5"
                src="@/assets/logo/MegaMillionslogo.png"
                @click="
                  ChangePage({
                    key: 'megamillions',
                    text: 'Mega Millions',
                    path: '/megamillions',
                    name: 'megamillions',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="
                ChangePage({
                  key: 'eurojackpot',
                  text: 'Euro Jackpot',
                  path: '/eurojackpot',
                  name: 'eurojackpot',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="80px"
                class="mx-auto my-5"
                src="@/assets/logo/eurojackpot.png"
                @click="
                  ChangePage({
                    key: 'menueurojackpot',
                    text: 'Euro Jackpot',
                    path: '/eurojackpot',
                    name: 'eurojackpot',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="
                ChangePage({
                  key: 'menupowerBallAus',
                  text: 'Power Ball Blue',
                  path: '/powerBallAus',
                  name: 'powerBallAus',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="130px"
                class="mx-auto my-5"
                src="@/assets/logo/Logo_AUS.png"
                @click="
                  ChangePage({
                    key: 'menupowerBallAus',
                    text: 'Power BallBlue',
                    path: '/powerBallAus',
                    name: 'powerBallAus',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="
                ChangePage({
                  key: 'minilotto',
                  text: 'Mini Lotto',
                  path: '/minilotto',
                  name: 'minilotto',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="150px"
                class="mx-auto my-5"
                src="@/assets/logo/Logo_JAP.png"
                @click="
                  ChangePage({
                    key: 'minilotto',
                    text: 'Mini Lotto',
                    path: '/minilotto',
                    name: 'minilotto',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <!-- <v-list-item style="margin-top: 0px">
              <v-img
                style="margin-top: -30px"
                max-width="150px"
                class="mx-auto my-5"
                src="@/assets/logo/blue_side.png"
                @click="
                  ChangePage({
                    key: 'menubluedragon',
                    text: 'bluedragon',
                    name: 'bluedragon',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item> -->
            <!-- <v-divider></v-divider>
            <v-list-item
              style="margin-top: 0px"
              @click="
                ChangePage({
                  key: 'menulaolottery',
                  text: 'laolottery',
                  path: '/lotterylao',
                  name: 'laolottery',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="150px"
                class="mx-auto my-5"
                src="@/assets/logo/lao_side.png"
                @click="
                  ChangePage({
                    key: 'menulaolottery',
                    text: 'laolottery',
                    path: '/lotterylao',
                    name: 'laolottery',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <v-divider></v-divider> -->

            <!-- //////////////////////////////// -->
            <!-- <v-list-item
              style="margin-top: 0px"
              @click="
                ChangePage({
                  key: 'menujapanlottery',
                  text: 'japanlottery',
                  path: '/lotteryjapan',
                  name: 'japanlottery',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="150px"
                class="mx-auto my-5"
                src="@/assets/logo/japanlot.png"
                @click="
                  ChangePage({
                    key: 'menulaolottery',
                    text: 'laolottery',
                    path: '/lotterylao',
                    name: 'laolottery',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item> -->
            <v-divider></v-divider>
            <v-list-item class="text-center">
              <p style="margin: 0px">
                <b style="font-size: 0.8rem">การซื้อลอตเตอรี่</b>
              </p>
            </v-list-item>
            <v-list-item class="text-center pl-6" @click="popupnotopen()">
              <p
                v-if="!isLogedin"
                style="margin: 0px; font-size: 0.8rem"
                @click="popupnotopen()"
              >
                สมัคร/เข้าสู่ระบบ
              </p>
              <p
                v-if="isLogedin"
                style="margin: 0px; font-size: 0.8rem"
                @click="popupnotopen()"
              >
                ข้อมูลส่วนตัว
              </p>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              class="text-center pl-6"
              @click="
                popupnotopen()
              "
            >
              <p style="margin: 0px; font-size: 0.8rem">ประวัติสั่งซื้อ</p>
            </v-list-item>
            <v-divider></v-divider>
            <!-- <v-list-item
              class="text-center pl-6"
              @click="
                popupnotopen()
              "
            >
              <p style="margin: 0px; font-size: 0.8rem">คำถามที่พบบ่อย</p>
            </v-list-item>
            <v-divider></v-divider> -->
            <v-list-item
              class="text-center pl-6"
              @click="
                popupnotopen()
              "
            >
              <p style="margin: 0px; font-size: 0.8rem">เงื่อนไขการให้บริการ</p>
            </v-list-item>
            <v-divider></v-divider>
            <!-- <v-list-item
              class="text-center pl-6"
              @click="
                popupnotopen()
              "
            >
              <p style="margin: 0px; font-size: 0.8rem">ผลรางวัลทุกงวด</p>
            </v-list-item>
            <v-divider></v-divider> -->

            <!-- <v-list-item class="text-center" @click="gotoprize()">
              <p style="margin: 0px; font-size: 0.8rem">การขึ้นเงินรางวัล</p>
            </v-list-item>
            <v-divider></v-divider> -->
            <!-- <v-list-item class="text-center">
              <p
                style="margin: 0px; font-size: 0.8rem"
                @click="gotopurchaseconditions()"
              >
                เงื่อนไขและข้อตกลง
              </p>
            </v-list-item>
            <v-divider></v-divider> -->
            <!-- <v-list-item class="text-center" @click="gotoabout()">
              <p style="margin: 0px; font-size: 0.8rem">เกี่ยวกับเรา</p>
            </v-list-item>
            <v-divider></v-divider> -->
            <v-list-item
              class="text-center pl-6"
              @click="
                onCLickRegisDealer()
              "
            >
              <p
                style="margin: 0px; font-size: 0.8rem"
                @click="
                  onCLickRegisDealer()
                "
              >
                <!-- www.ddlottery.net/dealers-net -->
                สมัครคู่ค้า (Partner)
              </p>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              class="text-center pl-6"
              @click="
                ChangePage({
                  key: 'menucontact',
                  text: 'contact-net',
                  path: '/contact-net',
                  name: 'contact-net',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <p style="margin: 0px; font-size: 0.8rem">ติดต่อเรา</p>
            </v-list-item>
            <!-- <v-list-item
              v-for="item in Navigator"
              :key="item.key"
              @click="ChangePage(item)"
            >
              <v-badge
                color="green"
                :content="$store.state.Carts.length"
                inline
                v-if="$store.state.Carts.length && item.name == 'cart'"
              >
              </v-badge>
              <span style="font-size: 13px">{{ item.text }}</span>
            </v-list-item> -->
          </v-list-item-group>
        </v-list> </v-navigation-drawer
      >d

      <v-main>
        <!-- <v-main :class="!isLogedin ? 'pb-10' : 'pb-10 mt-16'"> -->
        <router-view />
      </v-main>

      <!-- <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-btn
            small
            color="#00B900"
            dark
            absolute
            bottom
            right
            fixed
            elevation="2"
            rounded
            style="margin-bottom: 90px; z-index: 2; padding-top: -20px"
            @click="printpo"
            class="btnPrint"
          >
            <v-img
              src="@/assets/line-logo.png"
              width="20px"
              class="ml-0"
            ></v-img>
          </v-btn>
        </v-col>
      </v-row> -->
    </v-app>

    <back-to-top visibleoffset="400" bottom="100px" right="20px"
      ><button type="button" class="btn btn-info btn-to-top">
        <i class="fa fa-chevron-up"></i></button
    ></back-to-top>

    <!-- <a-page-header
      style="border: 0px solid rgb(235, 237, 240)"
      title=""
      sub-title=""
    > -->
    <!-- <a-avatar src="@/assets/bdg-logo.png" /> -->
    <!-- <v-img
        max-width="80px"
        src="@/assets/bdg-logo.png"
        class="mx-auto"
      ></v-img>
      <a-menu mode="horizontal" align="left" :overflowedIndicator="menuRight">
        <a-menu-item
          v-for="item in Navigator"
          :key="item.key"
          @click="ChangePage(item)"
        >
          <v-badge
            color="green"
            :content="content"
            v-if="content != 0 && item.name == 'cart'"
            offset-x="-60px"
            offset-y="-10px"
          >
          </v-badge>
          <a-icon :type="item.icon" />
          <span style="font-size: 14px;">{{ item.text }}</span>
        </a-menu-item>
      </a-menu>
    </a-page-header> -->

    <!-- <v-main class="div_overflow pa-4"> -->

    <v-bottom-navigation
      fixed
      :value="value"
      color="#185394"
      height="80"
      style="font-size: 20px"
    >
      <v-btn value="history" class="btm-btn" @click="drawer = true">
        <span style="font-size: 12px; font-weight: 700">เมนู</span>
        <v-icon>mdi-card-text-outline</v-icon>
        <!-- <v-icon>mdi-map-marker</v-icon> -->
      </v-btn>
      <v-btn value="login" class="btm-btn" to="/member" v-if="!isLogedin">
        <span style="font-size: 12px; font-weight: 700">เข้าสู่ระบบ</span>
        <v-icon>mdi-lock-outline</v-icon>
        <!-- <v-icon>mdi-map-marker</v-icon> -->
      </v-btn>
      <v-btn value="login" class="btm-btn" to="/safe" v-if="isLogedin">
        <span style="font-size: 12px; font-weight: 700">ประวัติสั่งซื้อ</span>
        <v-icon>mdi-history</v-icon>
        <!-- <v-icon>mdi-map-marker</v-icon> -->
      </v-btn>
      <v-btn value="login" class="btm-btn" to="/lotterythai">
        <!-- <v-btn value="login" class="btm-btn" to="/menuall"> -->
        <span style="font-size: 12px; font-weight: 700">ซื้อลอตเตอรี่</span>
        <v-icon color="">mdi-text-box-multiple-outline</v-icon>
      </v-btn>
      <v-btn
        value="login"
        class="btm-btn"
        to="/cart"
        v-if="$store.state.Carts.length === 0"
      >
        <span style="font-size: 12px; font-weight: 700">ตะกร้่า</span>
        <v-icon class="text-h5 mr-2" color="">mdi-cart-outline</v-icon>
      </v-btn>
      <v-btn
        value="login"
        class="btm-btn"
        to="/cart"
        v-if="$store.state.Carts.length"
      >
        <span style="font-size: 12px; font-weight: 700">ตะกร้่า</span>
        <v-badge
          color="green"
          overlap
          :content="$store.state.Carts.length"
          v-if="$store.state.Carts.length"
        >
          <v-icon class="text-h5 mr-2" color="">mdi-cart-outline</v-icon>
        </v-badge>
      </v-btn>
      <v-btn
        value="login"
        class="btm-btn"
        to="/lotterythai"
        small
        @click="printpo()"
      >
        <span style="font-size: 12px; font-weight: 700">ติดต่อเรา</span>
        <v-img
          src="@/assets/line-logo.png"
          max-width="25px"
          max-height="25px"
          class="ml-0"
        ></v-img>
      </v-btn>

      <!-- <v-btn value="powerBall" class="btm-btn" @click="clickPoweball()">
        <span style="font-size: 14px; font-weight: 700">พาวเวอร์บอล</span>
        <v-img
          max-width="95px"
          max-height="30px"
          class="mx-auto"
          src="@/assets/logo/powerball_side.png"
        ></v-img>
      </v-btn> -->
      <!-- <v-btn value="lottolao" class="btm-btn" @click="clickLao()">
        <span style="font-size: 14px; font-weight: 700">ลอตเตอรี่ลาว</span>
        <v-img
          max-width="75px"
          max-height="20px"
          class="mx-auto"
          src="@/assets/logo/lao_side.png"
        ></v-img>
      </v-btn> -->
      <!-- <v-btn value="lottolao" class="btm-btn" @click="clickLao()">
        <span style="font-size: 14px; font-weight: 700">ลอตเตอรี่ญี่ปุ่น</span>
        <v-img
          max-width="75px"
          max-height="20px"
          class="mx-auto"
          src="@/assets/logo/japanlot.png"
        ></v-img>
      </v-btn> -->
      <!-- <v-btn value="bluedragon" class="btm-btn" @click="bluedragon()">
        <span style="font-size: 14px; font-weight: 700">มังกรฟ้า</span>
        <v-img
          max-width="75px"
          max-height="30px"
          class="mx-auto"
          src="@/assets/logo/blue_side.png"
        ></v-img>
        
      </v-btn> -->
    </v-bottom-navigation>
    <div class="text-center">
      <v-dialog v-model="dialog" width="96vw">
        <v-card>
          <v-card-title class="white--text" style="background-color: #97b189">
            <span style="">ข้อตกลงและเงื่อนไข</span>
            <span style="">ในการซื้อลอตเตอรี่ออนไลน์</span>
            <span style="">ผ่านมังกรฟ้า</span>
            <br />
            <span style=""> โดย บริษัท มังกร ดิจิตอล จำกัด </span>
            <span style="">(BD00029D) ตัวแทนจำหน่ายยี่ปั๊วรายใหญ่</span>
          </v-card-title>

          <v-card-text>
            <span
              >1. มังกรฟ้าและ Bluedragonคือ
              แพลตฟอร์มช่องทางการจำหน่ายสลากกินแบ่งรัฐบาล หรือ ลอตเตอรี่
              ในราคาใบละ 80 บาท
              โดยผู้จำหน่ายสลากจะเรียกเก็บค่าบริการในการค้นหาตัวเลขตามที่ลูกค้าต้องการ</span
            ><br />
            <span
              >2. บริษัท มังกร ดิจิตอล จำกัด คือ
              ตัวแทนผู้จัดจำหน่ายยี่ปั๊วรายใหญ่ รหัส BD00029D ของ มังกรฟ้า
              ลอตเตอรี่ออนไลน์</span
            ><br />
            <span
              >3. ลูกค้าผู้ซื้อสลากกินแบ่งรัฐบาล หรือ ลอตเตอรี่
              ผ่านทางมังกรฟ้าจะต้องเป็นผู้มีอายุ 20 ปีขึ้นไป
              และไม่ได้ทำการสั่งซื้อขณะอยู่ในสถานศึกษา</span
            ><br />
            <span>
              4. การซื้อสลากกินแบ่งรัฐบาล หรือ
              ลอตเตอรี่ในรูปแบบออนไลน์ผ่านทางมังกรฟ้า
              เป็นการซื้อในรูปแบบการซื้อฝากร้านเท่านั้น
              หมายถึงเมื่อลูกค้าซื้อสลากกินแบ่งรัฐบาล หรือ
              ลอตเตอรี่กับผู้จำหน่ายสลากผ่านทางมังกรฟ้าแล้ว
              ลูกค้ายินยอมและสมัครใจที่จะฝากต้นฉบับสลากกินแบ่งรัฐบาล หรือ
              ลอตเตอรี่
              ไว้กับมังกรฟ้าให้เป็นผู้ดูแลรักษาและจัดเก็บอย่างเป็นระบบไว้แทน
              และสามารถติดต่อขอรับต้นฉบับได้หากถูกรางวัล</span
            ><br />
            <span
              >5. การอัพโหลด (Upload) หลักฐานการชำระเงิน หรือ สลิปโอนเงิน
              หากลูกค้าแนบสลิปผิดพลาด
              หรือเกิดข้อผิดพลาดประการใดเกี่ยวกับการอัปโหลดหลักฐานการชำระเงิน
              ให้ลูกค้ารีบติดต่อผู้จำหน่ายสลากทันทีเพื่อดำเนินการแก้ไขปัญหาที่เกิดขึ้น
              หรือแนบสลิปใหม่ หากกรณีไม่ได้รับการยืนยันคำสั่งซื้อ
              มังกรฟ้าขอสงวนสิทธิในการไม่รับผิดชอบในข้อผิดพลาดดังกล่าวใด ๆ
              ทั้งสิ้น </span
            ><br />
            <span
              >6.
              หากลูกค้าอัพโหลดหลักฐานการชำระเงินที่ไม่ตรงกับข้อมูลการสั่งซื้อ
              (สลิปที่ไม่ถูกต้อง)
              ด้วยมีเจตนากระทำการอย่างหนึ่งอย่างใดอันเป็นการเชื่อได้ว่าเป็นการกระทำโดยเจตนาทุจริต
              หากมังกรฟ้าสืบทราบ และ/หรือ พบเห็นการกระทำดังกล่าว
              มังกรฟ้าสงวนสิทธิและถือว่าการสั่งซื้อนั้นเป็นโมฆะ
              มังกรฟ้าจะดำเนินการยกเลิกคำสั่งซื้อและไม่มีการจัดส่งลอตเตอรี่ที่ถูกรางวัลให้แก่บุคคลนั้น
              พร้อมทั้งนำไอดี (ID) ผู้ใช้งานเข้าในระบบแบล็คลิสต์ (Blacklist)
              เพื่อไม่ให้ลูกค้าดำเนินการสั่งจองหรือสั่งซื้อใด ๆ
              ผ่านทางมังกรฟ้าได้อีกต่อไป
              และมังกรฟ้าขอสงวนสิทธิ์ในการดำเนินการแจ้งความร้องทุกข์ต่อพนักงานสอบสวนเพื่อดำเนินคดีต่อไป</span
            ><br />
            <span
              >7.
              รายการสั่งซื้อของลูกค้าจะดำเนินการสำเร็จต่อเมื่อผู้จำหน่ายสลากทำการตรวจสอบและอนุมัติรายการสั่งซื้อของลูกค้าแล้วเท่านั้น
              หากรายการสั่งซื้อสำเร็จ ลูกค้าจะได้รับข้อความ (SMS)
              แจ้งสถานะคำสั่งซื้อสำเร็จส่งไปยังหมายเลขโทรศัพท์มือถือที่ลูกค้าใช้ลงทะเบียนไว้จึงเป็นการเสร็จสิ้น
              คำสั่งซื้อสำเร็จ
              หากลูกค้าไม่ได้รับข้อความแจ้งคำสั่งซื้อไปยังหมายเลขโทรศัพท์ที่ลูกค้าใช้ลงทะเบียนไว้
              ขอให้ลูกค้ากรุณาติดต่อผู้จำหน่ายสลากที่ลูกค้าได้ติดต่อเพื่อซื้อลอตเตอรี่</span
            ><br />
            <span
              >8. หลังจากคำสั่งซื้อของลูกค้าสำเร็จแล้วนั้น
              ลูกค้าจะไม่สามารถทำการแก้ไข หรือ ทำการยกเลิกคำสั่งซื้อนั้นได้ 9.
              โอนเงินและอัพโหลดสลิปโอนเงินบนเวบไซต์ภายใน 1 ชม. เท่านั้น
              เพื่อป้องกันออเดอร์หมดเวลา แฃะถูกยกเลิกอัตโนมัติ 10.
              หากอัพโหลดสลิปด้วยตนเองไม่ได้ ส่งให้เราทางไลน์ช่วยอัพโหลดแทนได้
              (ทั้งนี้ แอดมินเป็นเพียงผู้ช่วยเหลืออัพโหลดสลิปโอนแทนเท่านั้น
              ดังนั้นกรุณาส่งสลิปแจ้งโอนก่อนหมดเวลามากกว่า 30 นาที)</span
            ><br />
            <span>
              11. หากส่งสลิปโอนแล้วหรืออัพโหลดสลิปโอนแล้ว แต่ยังไม่ได้รับ SMS
              ยืนยันออเดอร์สมบูรณ์ ใน 30 นาที
              ลูกค้าต้องแจ้งตัวแทนทันทีทางไลน์เท่านั้น</span
            ><br />
            <span
              >12. หากออเดอร์หมดเวลาทุกกรณี/ยกเลิกทุกกรณี
              ลูกค้าสามารถสั่งซื้อใหม่อีกครั้งได้ด้วยการแนบสลิปโอนเดิมที่โอนแล้ว
              หรือกรณีลอตเตอรี่หมดแล้วกรุณาติดต่อรับเงินโอนคืนเต็มจำนวนเท่านั้น</span
            ><br />
            <span
              >13. เลขสลากฯ
              ที่ถูกรางวัลของออเดอร์ที่สถานะหมดเวลา/ถูกยกเลิกในทุกกรณี
              ไม่สามารถขึ้นเงินรางวัลได้
              กรุณาติดต่อรับเงินโอนคืนค่าลอตเตอรี่เต็มจำนวนเท่านั้น
              ยอมรับข้อตกลงและเงื่อนไข
              ข้อตกลงและเงื่อนไขการสั่งซื้อลอตเตอรี่มังกรฟ้า ลอตเตอรี่ออนไลน์
              โดยบริษัท มังกร ดิจิตอล จำกัด
              เมื่อลูกค้าได้เลือกเลขสลากใส่ตะกร้าสินค้า
              และทำตามขั้นตอนบนเวบไซต์แล้ว ในการกดสั่งซื้อและกดยืนยัน ทางบริษัทฯ
              จะถือว่าลูกค้าได้ตกลงและยอมรับเงื่อนไขของ บจก. มังกร ดิจิตอล และ
              บจก. มังกรฟ้า ลอตเตอรี่ออนไลน์
              ที่แจ้งเตือนให้อ่านและยอมรับก่อนการกด "ยืนยัน" เรียบร้อยแล้ว •
              โอนเงินและอัพโหลดสลิปโอนเงินบนเวบไซต์ภายใน 1 ชม. เท่านั้น
              เพื่อป้องกันออเดอร์หมดเวลา •
              ลูกค้าต้องตรวจสอบสถานะออเดอร์ของตนเองหลังการสั่งซื้อทุกครั้งที่เมนู
              "ประวัติการสั่งซื้อ" • หากอัพโหลดสลิปด้วยตนเองไม่ได้
              ส่งให้เราทางไลน์ช่วยอัพโหลดแทนได้ (ทั้งนี้
              แอดมินเป็นเพียงผู้ช่วยเหลืออัพโหลดสลิปแทนเท่านั้น
              ดังนั้นกรุณาส่งสลิปแจ้งโอนก่อนหมดเวลามากกว่า 30 นาที) •
              หากส่งสลิปโอนแล้วหรืออัพโหลดสลิปโอนแล้ว แต่ยังไม่ได้รับ SMS
              ยืนยันออเดอร์สมบูรณ์ ใน 30 นาที
              ลูกค้าต้องแจ้งตัวแทนทันทีทางไลน์เท่านั้น •
              หากออเดอร์หมดเวลาทุกกรณี
              ลูกค้าสามารถสั่งซื้อใหม่อีกครั้งได้ด้วยการแนบสลิปเดิมที่โอนแล้ว
              หรือกรณีลอตเตอรี่หมดแล้วกรุณาติดต่อรับเงินโอนคืนเต็มจำนวน •
              เลขสลากฯ ที่ถูกรางวัลของออเดอร์ที่สถานะหมดเวลา/ถูกยกเลิกในทุกกรณี
              ไม่สามารถขึ้นเงินรางวัลได้</span
            >
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false"> ถอยกลับ </v-btn>
            <!-- <v-btn
              color="primary"
              @click="
                checkPrivacy = true;
                dialog = false;
              "
            >
              ฉันยอมรับ
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { decode } from "@/services/decode";
import { encode } from "@/services/encode";
export default {
  data() {
    return {
      time: "",
      min: 15,
      sec: 0,
      drawer: null,
      dialog: false,
      show: false,
      checkPrivacy: false,
      carts: [],
      content: 0,
      value: 1,
      group: null,
      isLogedin: false,
      menuRight: "อื่นๆ",
      Navigator: [
        {
          key: "menu1",
          text: "หน้าหลัก",
          path: "/",
          name: "home",
          icon: "mdi-home-outline",
        },
        {
          key: "powerBall",
          text: "Power Ball",
          path: "/powerBall",
          name: "powerBall",
          icon: "mdi-magnify",
        },
        // {
        //   key: "menu7",
        //   text: "ซื้อลอตเตอรี่",
        //   path: "/",
        //   name: "home",
        //   icon: "mdi-magnify",
        // },
        {
          key: "menu2",
          text: "สมัครสมาชิก",
          path: "/member",
          name: "member",
          icon: "mdi-account-outline",
        },
        {
          key: "menu3",
          text: "เข้าสู่ระบบ",
          path: "/member",
          name: "member",
          icon: "mdi-lock-outline",
        },
        // {
        //   key: "menu4",
        //   text: "ลอตเตอรี่ของฉัน",
        //   path: "/safe",
        //   name: "safe",
        //   icon: "mdi-card-text-outline",
        // },
        {
          key: "menu5",
          text: "ตะกร้า",
          path: "/cart",
          name: "cart",
          icon: "mdi-cart-outline",
        },
        {
          key: "menu99",
          text: "เงื่อนไขและข้อตกลง",
          // path: "/winprize",
          name: "privacy",
          icon: "mdi-text-box-check-outline",
        },
        {
          key: "menu6",
          text: "เกี่ยวกับเรา",
          path: "/contact",
          name: "contact",
          icon: "mdi-city-variant-outline",
        },
      ],
      NavigatorLogin: [
        {
          key: "menu1",
          text: "หน้าหลัก",
          path: "/index",
          name: "home",
          icon: "mdi-home-outline",
        },
        // {
        //   key: "menu7",
        //   text: "ซื้อลอตเตอรี่",
        //   path: "/",
        //   name: "home",
        //   icon: "mdi-magnify",
        // },

        {
          key: "powerBall",
          text: "Power Ball",
          path: "/powerBall",
          name: "powerBall",
          icon: "mdi-magnify",
        },
        {
          key: "menu5",
          text: "ตะกร้า",
          path: "/cart",
          name: "cart",
          icon: "mdi-cart-outline",
        },
        // {
        //   key: "menu3",
        //   text: "ลอตเตอรี่ของฉัน",
        //   path: "/safe",
        //   name: "safe",
        //   icon: "mdi-card-text-outline",
        // },
        // - [ ] - [ ] แจ้งถูกลอตเตอรี่ขึ้นรางวัล ลิ้งไป https://www.bluedragonlottery.shop/winlotteryform
        // {
        //   key: "menu4",
        //   text: "แจ้งถูกล้อตเตอรี่ขึ้นรางวัล",
        //   path: "/winprize",
        //   name: "winprize",
        //   icon: "mdi-cash",
        // },
        {
          key: "menu99",
          text: "เงื่อนไขและข้อตกลง",
          // path: "/winprize",
          name: "privacy",
          icon: "mdi-text-box-check-outline",
        },
        {
          key: "menu2",
          text: "ข้อมูลส่วนตัว",
          path: "/member",
          name: "member",
          icon: "mdi-account",
        },
        {
          key: "menu6",
          text: "เกี่ยวกับเรา",
          path: "/contact",
          name: "contact",
          icon: "mdi-city-variant-outline",
        },
        {
          key: "menu9",
          text: "ออกจากระบบ",
          // path: "/contact",
          name: "logout",
          icon: "mdi-logout-variant",
        },
      ],
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
    "$store.state.Carts": {
      handler(array) {},
      deep: true,
    },
  },
  created() {
    if (Object.prototype.hasOwnProperty.call(localStorage, "DDLotUser")) {
      this.Navigator = this.NavigatorLogin;
      this.isLogedin = true;
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      this.menuRight = user.user.firstName;
      console.log("userrr", user.user.firstName);
    }
    this.getCart();
  },
  methods: {
    onCLickRegisDealer(){
      window.open('https://xn--80-3qi5dcjgd2e0a7j4a7a5l.com/', '_blank')
    },
    async popupnotopen(){
      await this.$swal.fire({
        icon: "success",
        html: "พบกับการเปิดตัว บางกอกลอตเตอรี่.com แพลตฟอร์มสลากดิจิทัลเอกชน ราคา 80 บาท เร็วๆ นี้ <br/>แอดไลน์เพิ่มเพื่อนไว้รอซื้อได้เลย คลิกที่นี่<br/><a href='https://bit.ly/3yRVDzc'>https://bit.ly/3yRVDzc</a>",
        showConfirmButton: true,
        // timer: 1500,
      });
    },
    gotoDotNet() {
      this.$router.push("/");
    },
    gotohowtobuy() {
      // window.open("www.ddlottery.com/howtobuy");
      window.open("www.ddlottery.com/howtobuy", "_blank");
    },
    gotomember() {
      // console.log("error");
      this.$router.push("/member");
    },
    gotoprize() {
      window.open("www.ddlottery.com/prize", "_blank");
    },
    gotopurchaseconditions() {
      window.open("www.ddlottery.com/purchaseconditions", "_blank");
    },
    gotoabout() {
      window.open("www.ddlottery.com/aboutus", "_blank");
    },
    gotodealer() {
      window.open("www.ddlottery.com/dearler", "_blank");
    },
    gotocontact() {
      window.open("www.ddlottery.com/contact", "_blank");
    },
    bluedragon() {
      window.open("https://bit.ly/2NV4evM", "_blank");
    },
    clickPoweball() {
      this.$router.push({ path: "/powerBall" }).catch(() => {});
    },
    clickLotto() {
      window.open("https://bit.ly/2NV4evM", "_blank");
    },
    async clickLao() {
      // await this.$swal.fire({
      //   icon: "warning",
      //   text: "พบกับลอตเตอรี่จากประเทศลาว เร็วๆนี้",
      //   showConfirmButton: true,
      //   // timer: 1500,
      // });
      this.$router.push("/lotterylao");
    },
    myTimer() {
      if (this.sec === 0) {
        this.min--;
        this.sec = 59;
      } else {
        this.sec--;
      }

      this.time = this.min + ":" + this.sec;
    },
    logout() {
      this.isLogedin = false;
      console.log("ออกแล้วโว้ย");
      // localStorage.removeItem
      localStorage.removeItem("DDLotUser");
      this.$router.push({ path: "/" }).catch(() => {});
      window.location.reload();
      // this.created()
    },
    async getCart() {
      this.carts = [];
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      // console.log("user", user.token);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/cart/findAllByUser/` + user.user.id,
        auth
      );

      this.$store.dispatch("ActionsSetCars", response.data.data);
      console.log("response", response.data.data);
      this.carts = response.data.data;

      this.content = this.carts.length;
      console.log("carts", this.carts);
    },
    async ChangePage(val) {
      // console.log("เข้า ChangePage", val);
      // // console.log(val, this.$router.currentRoute)
      if (val.name === "logout") {
        console.log("ออกแล้วโว้ย");
        // localStorage.removeItem
        localStorage.removeItem("DDLotUser");
        this.$router.push({ path: "/" }).catch(() => {});
        window.location.reload();
      } else if (val.name === "bluedragon") {
        this.bluedragon();
      } else if (val.name === "laolottery") {
        // this.dialogPrivacy();
        // window.open("laolottery");
        this.clickLao();
      } else if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    dialogPrivacy() {
      this.dialog = true;
    },
    printpo() {
      window.open("https://lin.ee/Ayc1oQR");
    },
    async ChangePageBtm(val) {
      // console.log("เข้า ChangePage", val);
      // // console.log(val, this.$router.currentRoute)

      this.$router.push({ path: val }).catch(() => {});
    },
  },
};
</script>
<style scoped>
.btn-to-top {
  width: 40px;
  height: 40px;
  padding: 0px 0px;
  border-radius: 50%;
  font-size: 22px;
  color: white;
  line-height: 22px;
  background: #76ade9;
}

.div_overflow {
  overflow: auto;
  width: 100%;
  height: 94vh;
}
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
.btm-btn {
  /* background-color: transparent !important; */
  padding: 10;
  border: 1px solid #235081;
}
.btm-btn::before {
  background-color: transparent !important;
}
.pb-10 {
  padding-bottom: 100px;
}
</style>
